import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Privacy Policy" />
    <div className="policy container p-5">

      <p className="p1"><span
        className="s1"><strong><em>INFORMATIVA SULLA PROTEZIONE DEI DATI PERSONALI</em></strong></span></p>
      <p className="p2"><span className="s2">La presente informativa &egrave; resa, nel rispetto degli artt. 13 e 14 del Regolamento UE 679/2016 (di seguito &ldquo;Regolamento&rdquo;), agli utilizzatori (di seguito: &ldquo;Utenti&rdquo; oppure &ldquo;Utente&rdquo;) dell&rsquo;applicazione mobile relativa al servizio (di seguito: &ldquo;App&rdquo;) di propriet&agrave; di MIO PEDIATRA Srl, Titolare del Trattamento dei dati personali (di seguito: &ldquo;Titolare&rdquo;), ed ha lo scopo di descrivere le modalit&agrave; di gestione dell&rsquo;App con riferimento al trattamento dei dati personali, nonch&eacute; di consentire agli Utenti di conoscere le finalit&agrave; e le modalit&agrave; di trattamento dei dati personali da parte del Titolare in caso di loro conferimento.</span>
      </p>
      <p className="p2"><span className="s2">I servizi offerti dal Titolare sono rivolti a persone di et&agrave; superiore ai 18 anni. Qualora il Titolare dovesse venire a conoscenza del trattamento di dati di minori di 18 anni di et&agrave; senza un valido consenso dei genitori o di un tutore legale, si riserva il diritto di interrompere unilateralmente la fruizione del servizio offerto, nonch&eacute; di cancellare i dati acquisiti.</span>
      </p>
      <p className="p3"><span
        className="s1"><strong>Principi applicabili al trattamento dei dati personali</strong></span></p>
      <p className="p2"><span className="s2">Il Titolare, ai sensi e per gli effetti del Regolamento, rende noto che la citata normativa prevede la tutela delle persone fisiche rispetto al trattamento dei dati personali, e che tale trattamento sar&agrave; improntato ai principi di correttezza, liceit&agrave;, trasparenza e di tutela della riservatezza e dei diritti fondamentali.</span>
      </p>
      <p className="p3"><span className="s1"><strong>Tipologia di utenti</strong></span></p>
      <p className="p2"><span className="s2">In relazione all&rsquo;utilizzo dell&rsquo;App si distinguono le seguenti tipologie di Utenti:</span>
      </p>
      <p className="p2"><span className="s2">Gli <strong>Utenti Semplici</strong> <em>(Genitori dei pazienti)</em> che dovranno registrarsi prima dell&rsquo;uso.</span>
      </p>
      <p className="p2"><span className="s2">Il Titolare tratta dati c.d. particolari (es. dati relativi allo stato di salute) degli Utenti.</span>
      </p>
      <p className="p3"><span
        className="s1"><strong>Finalit&agrave;, base giuridica del trattamento e facoltativit&agrave; del conferimento</strong></span>
      </p>
      <p className="p2"><span
        className="s2">I dati personali raccolti dal Titolare attraverso l&rsquo;utilizzo dell&rsquo;App, verranno trattati, con il consenso degli Interessati, per le finalit&agrave; di seguito descritte:</span>
      </p>
      <ol className="ol1">
        <li className="li2"><span className="s2">Consentire a Pediatri di fornire ai pazienti che utilizzano l&rsquo;App un servizio di comunicazione/richiesta di informazioni inerenti allo stato di salute del minore o altre prestazioni sanitarie, comprensivo dell&rsquo;invio di sms di notifica o di notifiche via APP;</span>
        </li>
        <li className="li2"><span className="s2">Consentire l&rsquo;invio agli Utenti di comunicazioni promozionali relative a prodotti e/o servizi del Titolare. Tale finalit&agrave; richiede una espressa accettazione opzionale e facoltativa;</span>
        </li>
        <li className="li2"><span className="s2">Verificare la completezza, la validit&agrave; e la correttezza dei dati forniti, anche al fine di risalire ad autori di eventuali illeciti ed evitare frodi informatiche unicamente nel caso di specifiche richieste delle competenti Autorit&agrave;.</span>
        </li>
      </ol>
      <p className="p2"><span
        className="s2">Non vengono effettuate attivit&agrave; di profilazione degli utenti, n&eacute; per scopi commerciali n&eacute; per altre finalit&agrave;</span>
      </p>
      <p className="p2"><span
        className="s2">Il conferimento dei dati per le finalit&agrave; di cui sopra &egrave; facoltativo, ma un eventuale rifiuto comporter&agrave; l&rsquo;impossibilit&agrave; del Titolare di erogare i servizi sopra indicati. In particolare, in difetto del consenso, non sar&agrave; possibile consentire la corretta gestione del processo di comunicazione tramite notifiche e/o invio di sms e dunque l&rsquo;erogazione stessa dei servizi.</span>
      </p>
      <p className="p3"><span
        className="s1"><strong>Modalit&agrave; di trattamento e conservazione dei dati personali</strong></span></p>
      <p className="p2"><span className="s2">Il Titolare assicura che i dati personali sono trattati nel pieno rispetto del Regolamento, in formato cartaceo e/o elettronico, anche mediante l&rsquo;ausilio di procedure automatizzate. Il trattamento potr&agrave; essere effettuato anche attraverso strumenti automatizzati atti a memorizzare, gestire e trasmettere i dati stessi.</span>
      </p>
      <p className="p2"><span className="s2">I dati raccolti ed oggetto di trattamento saranno protetti con metodologie fisiche e logiche tali da ridurre al minimo i rischi di accesso non consentito, diffusione, perdita e distruzione dei dati, ai sensi degli art. 25 e 32 del Regolamento.</span>
      </p>
      <p className="p2"><span className="s2">Il trattamento dei dati avr&agrave; durata non superiore a quanto necessario per soddisfare le finalit&agrave; per le quali sono stati raccolti.</span>
      </p>
      <p className="p2"><span
        className="s2">Ai sensi dell&rsquo;art. 7 comma 3 del Regolamento, l&rsquo;interessato avr&agrave; la facolt&agrave; in qualsiasi momento ed in modo facile e veloce di revocare il consenso al trattamento e richiedere la cancellazione dei propri dati personali, inviando comunicazione al Titolare alla casella: &nbsp;privacy@miopediatra.com</span>
      </p>
      <p className="p2"><span className="s2">A seguito della richiesta di cancellazione da parte dell&rsquo;Utente, tutti i dati personali dello stesso verranno cancellati, fatta salva l&rsquo;ulteriore conservazione prevista da obblighi normativi.</span>
      </p>
      <p className="p2"><span className="s2">Inoltre, nel caso in cui l&rsquo;Utente sia stato richiamato, sospeso o sanzionato per comportamenti fraudolenti o sospetti, il Titolare si riserva la facolt&agrave; di tenere i dati personali relativi a tale Utente per un periodo di 2 (due) anni dalla richiesta di cancellazione, al fine di prevenire il verificarsi e/o il ripetersi di eventuali frodi in danno del Titolare stesso.</span>
      </p>
      <p className="p3"><span className="s1"><strong>Destinatari dei dati personali</strong></span></p>
      <p className="p2"><span className="s2">I dati personali raccolti potranno essere trattati da soggetti o categorie di soggetti che agiscono come Responsabili del trattamento dei dati ai sensi dell&rsquo;art. 28 del Regolamento o che sono autorizzati al trattamento dei dati ai sensi dell&rsquo;art. 29 del Regolamento.</span>
      </p>
      <p className="p2"><span className="s2">Inoltre, per alcuni servizi, i dati potranno essere comunicati a Partner che collaborano o utilizzano i servizi del Titolare con l&rsquo;unico intento di erogare i servizi richiesti dall&rsquo;Utente. In questi casi, i Partner sono autonomi titolari, pertanto il Titolare non &egrave; responsabile del trattamento dei dati da parte degli stessi. Il Titolare inoltre non &egrave; responsabile dei contenuti e del rispetto della normativa in tema di protezione dei dati personali da parte di siti non gestiti dal Titolare.</span>
      </p>
      <p className="p2"><span className="s2">Al di fuori delle suddette ipotesi, i dati personali non saranno oggetto di comunicazione se non nei confronti di soggetti, enti e Autorit&agrave; verso cui la comunicazione sia obbligatoria in forza di disposizioni di legge o di regolamento.</span>
      </p>
      <p className="p3"><span className="s1"><strong>Trasferimento dei dati a un paese terzo o a un&#39;organizzazione internazionale</strong></span>
      </p>
      <p className="p2"><span className="s2">I dati personali raccolti tramite l&rsquo;App, potranno essere trasferiti al di fuori del territorio nazionale, solo ed esclusivamente per l&rsquo;esecuzione dei servizi richiesti tramite l&rsquo;App e nel rispetto delle specifiche disposizioni previste dal Regolamento.</span>
      </p>
      <p className="p2"><span className="s2">Alcuni dati personali potrebbero essere condivisi con destinatari situati al di fuori dello Spazio Economico Europeo. Il Titolare assicura che il trattamento dei dati personali da parte di questi destinatari avviene nel rispetto del Regolamento.</span>
      </p>
      <p className="p3"><span className="s1"><strong>Raccolta dati di navigazione<span
        className="Apple-converted-space">&nbsp;</span></strong></span></p>
      <p className="p2"><span className="s2">I sistemi informatici e le procedure tecniche e software sottostanti il funzionamento dell&rsquo;App acquisiscono, nel corso del loro normale esercizio, alcuni dati personali la cui trasmissione &egrave; implicita nei meccanismi di accesso e funzionamento e dei protocolli in uso su Internet.</span>
      </p>
      <p className="p2"><span className="s2">Ogni volta che l&rsquo;Utente si collega all&rsquo;App e ogni volta che richiama o richiede un contenuto i dati d&rsquo;accesso vengono memorizzati presso i nostri sistemi, sotto forma di file di dati tabellari o lineari.</span>
      </p>
      <p className="p2"><span className="s2">Rientrano in questa categoria di dati, ad esempio, gli indirizzi IP, i nomi a dominio dei computer utilizzati dagli utenti che si connettono a L&rsquo; App, la richiesta da parte del browser dell&rsquo;Utente, sotto forma di indirizzi in notazione URI (Uniform Resource Identifier), la data e l&rsquo;ora della richiesta al server, il metodo utilizzato nel sottoporre la richiesta al server, la quantit&agrave; di dati trasmessa, il codice numerico indicante lo stato della risposta data dal server ed altri parametri relativi al sistema operativo e all&rsquo;ambiente informatico dell&rsquo;Utente.</span>
      </p>
      <p className="p2"><span className="s2">Questi dati potranno essere utilizzati dal Titolare al solo fine di ricavare informazioni statistiche anonime sull&rsquo;uso dell&rsquo; App al fine di individuare le pagine preferite dagli Utenti e fornire dunque contenuti sempre pi&ugrave; adeguati e per controllarne il corretto funzionamento. Su richiesta dell&rsquo;Autorit&agrave;, i dati potrebbero essere utilizzati per l&rsquo;accertamento di responsabilit&agrave; in caso di ipotetici reati informatici ai danni dell&rsquo; App o dei suoi Utenti.</span>
      </p>
      <p className="p3"><span className="s1"><strong>Diritti dell&rsquo;interessato</strong></span></p>
      <p className="p2"><span className="s2">Ai sensi degli artt. dal 15 al 22 del Regolamento, l&rsquo;Utente, quale soggetto interessato, ha la facolt&agrave; di esercitare specifici diritti inerenti i suoi dati personali. In particolare, l&rsquo;Interessato ha diritto di ottenere:</span>
      </p>
      <ol className="ol2">
        <li className="li2"><span className="s2">la <strong>conferma</strong> dell&rsquo;esistenza o meno di dati personali che lo riguardano, anche se non ancora registrati, in forma concisa, trasparente, intelligibile e facilmente accessibile, con un linguaggio semplice e chiaro;</span>
        </li>
        <li className="li2"><span className="s2"><strong>l&rsquo;indicazione</strong>:</span>
          <ol className="ol3">
            <li className="li2"><span className="s2">dell&rsquo;<strong>origine</strong> dei dati personali;</span></li>
            <li className="li2"><span
              className="s2">delle <strong>finalit&agrave;</strong> e <strong>modalit&agrave;</strong> di trattamento;</span>
            </li>
            <li className="li2"><span className="s2">dei <strong>legittimi interessi</strong> perseguiti dal Titolare o da terzi;</span>
            </li>
            <li className="li2"><span className="s2">degli <strong>eventuali destinatari</strong> o le eventuali categorie di destinatari dei dati personali;</span>
            </li>
            <li className="li2"><span
              className="s2">dell&rsquo;eventuale intenzione del titolare di <strong>trasferire</strong> dati personali a un paese terzo o a un&#39;organizzazione internazionale;</span>
            </li>
            <li className="li2"><span
              className="s2">del <strong>periodo di conservazione</strong> dei dati personali;</span></li>
            <li className="li2"><span
              className="s2">della <strong>logica applicata</strong>, nonch&eacute; l&rsquo;importanza e le conseguenze previste di tale trattamento per l&rsquo;interessato, in caso di trattamento effettuato con l&rsquo;ausilio di strumenti elettronici nell&rsquo;ambito di un processo automatico di raccolta e/o profilazione;</span>
            </li>
            <li className="li2"><span className="s2">degli <strong>estremi identificativi del Titolare</strong>, dei Responsabili, dell&rsquo;eventuale Rappresentante designato e del Responsabile della protezione dei Dati (c.d. DPO);</span>
            </li>
            <li className="li2"><span className="s2">dei soggetti e delle categorie di soggetti ai quali i dati personali possono essere comunicati o che possono venirne a conoscenza in qualit&agrave; di rappresentante designato nel territorio dello Stato, di responsabili o incaricati;</span>
            </li>
          </ol>
        </li>
        <li className="li2"><span
          className="s2">la possibilit&agrave; di proporre un <strong>reclamo</strong> ad un&rsquo;Autorit&agrave; di controllo;</span>
        </li>
        <li className="li2"><span className="s2">l&rsquo;
          <strong>aggiornamento</strong>, la <strong>rettificazione</strong> ovvero, quando vi ha interesse, l&rsquo;
          <strong>integrazione</strong> dei dati;</span></li>
        <li className="li2"><span className="s2">la <strong>cancellazione</strong>, la <strong>trasformazione in forma anonima</strong> o il <strong>blocco</strong> dei dati trattati in violazione della legge, compresi quelli di cui non &egrave; necessaria la conservazione in relazione agli scopi per i quali i dati sono stati raccolti o successivamente trattati;</span>
        </li>
        <li className="li2"><span className="s2">la <strong>limitazione</strong> al trattamento;</span></li>
        <li className="li2"><span className="s2">la <strong>portabilit&agrave;</strong> dei dati personali che lo riguardano ad altro Titolare del trattamento;</span>
        </li>
        <li className="li2"><span className="s2">la <strong>revoca</strong> del trattamento;</span></li>
        <li className="li2"><span className="s2">l&rsquo;<strong>attestazione</strong> che le operazioni di cui alle lettere a) e b) sono state portate a conoscenza, anche per quanto riguarda il loro contenuto, di coloro ai quali i dati sono stati comunicati o diffusi, eccettuato il caso in cui tale adempimento si rivela impossibile o comporta un impiego di mezzi manifestatamente sproporzionato rispetto al diritto tutelato;</span>
        </li>
        <li className="li2"><span className="s2">l&rsquo;<strong>opposizione</strong>, in tutto o in parte, per motivi legittimi, al trattamento dei dati personali che lo riguardano, ancorch&eacute; pertinenti allo scopo della raccolta.</span>
        </li>
      </ol>
      <p className="p3"><span
        className="s1"><strong>Titolare del trattamento e responsabile della protezione dei dati</strong></span></p>
      <p className="p2"><span
        className="s2">Per esercitare i diritti al punto precedente, l&rsquo;interessato potr&agrave; rivolgersi in ogni momento al Titolare e/o al Responsabile della Protezione dei Dati per eventuali comunicazioni in merito al trattamento dei propri Dati Personali, o per conoscere l&rsquo;elenco aggiornato degli eventuali Responsabili del Trattamento nominati dalla Societ&agrave;, inviando comunicazione ai contatti di seguito riportati:</span>
      </p>
      <p className="p2"><span
        className="s2"><strong>MIO PEDIATRA SRL&nbsp;</strong>in persona del legale rappresentante <em>pro tempore</em>, con sede in Via Giacomo Medici, 9, 35138 Padova PD.</span>
      </p>
      <p className="p2"><span className="s2">Mail dedicata: privacy@miopediatra.com</span></p>
      <p className="p3"><span className="s1"><strong>Modifiche</strong></span></p>
      <p className="p2"><span
        className="s2">La presente informativa potrebbe essere soggetta a modifiche. Affinch&eacute; l&rsquo;Utente sia sempre aggiornato, il Titolare lo invita a visitare periodicamente questa pagina. Inoltre, qualora tali modifiche abbiano un impatto sui dati relativi all&rsquo;Utente (ad esempio qualora il Titolare intenda trattare i dati personali dell&rsquo;Utente per finalit&agrave; diverse da quelle precedentemente comunicate nella presente Informativa), il Titolare informer&agrave; l&rsquo;Utente prima che tali modifiche abbiano effetto, pubblicandole con la massima evidenza sul proprio L&rsquo; App.</span>
      </p>
      <p className="p2"><span className="s2">Per eventuali chiarimenti non esiti a contattare il nostro responsabile della protezione dei dati personali (DPO): <a
        href="mailto:giulia.businaro@tutelasanitaria.it"><span
        className="s3">giulia.businaro@tutelasanitaria.it</span></a><span className="Apple-converted-space">&nbsp;</span></span>
      </p>
    </div>
  </Layout>
)

export default NotFoundPage
